<template>
    <div class="grid">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
      <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                    <!-- Customers -->
                     {{ $t('citizens') }}
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <!-- <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  :label="$t('add')" 
                  icon="pi pi-plus"
                  v-tooltip.bottom="$t('add')" 
                  @click="add_customer"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card" id="custom_card">

          <Toast />
        

        <Toolbar class="mb-4">
          <template v-slot:start>
            
            <div class="field ">
                  <span class="p-float-label">
                    <InputText
                      format="text"
                      v-model="search_data"
                      @change="search(1)"
                    >
                    </InputText>
                    <label>{{ $t('search_cust') }}</label>
                  </span>
                </div>
                <div class="field col-12 md:col-4" >
                  <span class="p-float-label" v-if="user_type=='Super Admin'">
                    <Dropdown
                      id="client_dropdown"
                      v-model="client_selected"
                      :options="client_dropdown"
                      :value="client_dropdown"
                      optionLabel="name"
                      filter
                      @change="search(1)"
                    ></Dropdown>
                    <label for="Client">{{ $t('client') }}</label>
                  </span>
                </div>


          </template>
          <template v-slot:end>
             <div class="d-flex justify-content-end" style="text-align: right" >
            <downloadexcel
              :header="$t('list_of_citizen')" 
              :name="$t('citizen_list')" 
              :fetch="fetchData" 
              :fields="jsonFields"
            >
              <Button 
                icon="pi pi-file-excel"  
                class="btn_green"
                v-tooltip.bottom="'Excel'"
               
              />
             
      </downloadexcel>
      <div style=" font-weight: bold ; font-size: 12px; margin-top: 2%;">
                <span>{{ $t('total_customers') }} : </span>{{ totalRecords }}
            </div>
    </div>
          </template>
        </Toolbar>

          
         
          
          
          
          <DataView
            :value="dataviewValue"
            :layout="layout"
            :paginator="true"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="PerPageOptions"
            :rows="9"
            :sortOrder="sortOrder"
            :sortField="sortField"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <template #grid="slotProps">
              <div class="grid grid-nogutter">
                <div
                  class="col-12 md:col-4"
               
                >
                  <div
                   style="height: 170px;width: 280px;"
                    class="card m-3 border-1 surface-border zoom"
                    id="custom_card_left"
                    @mouseover="showButtons(index)"
                    @mouseout="hideButtons(index)"
                    :style="{
                      'border-left': '5px solid ' + slotProps.data.color + ' !important',
                    }"
                  >
                    <div class="grid">
                      <div class="col-12 md:col-4">
                        <div class="flex align-items-center">
                          <img
                            style="border-radius: 50%; width: 35px; height: 35px "
                            v-if="slotProps.data.profile_image"
                            :src="slotProps.data.profile_image"
                            :alt="slotProps.data.profile_image"
                          />
                          <!-- <img
                            style="border-radius: 50%; width: 80px; height: 80px"
                            v-else
                            src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                          /> -->
                          <!-- <span v-else class="i-circle" :style="'background-color:'+(this.color_data[index]?this.color_data[index].code:'skyblue')">{{convert_name(slotProps.data.name)}}</span> -->

                          <span 
                          v-else 
                          class="i-circle" 
                          :style="{
                            backgroundColor: this.color_data[index] ? this.color_data[index].code : 'skyblue',
                            height: '25px', 
                            width: '25px'    
                          }">
                          {{convert_name(slotProps.data.name)}}
                        </span>



                          <!-- <img style="border-radius: 50%; width: 80px; height: 80px" v-else :src="image(item.name)"> -->
                        </div>
                      </div>
                      <div class="col-12 md:col-6">
                        <div>
                          <span
                            style="
                              color: #6b719b;
                              font-size: 15px;
                              font-weight: 500;
                              cursor: pointer;
                            "
                            @click="view_customer(slotProps.data)"
                            >{{ slotProps.data.name }}</span
                          >&nbsp;
                        </div>
                        <br />
                        <div style="max-width: 250px; word-wrap: break-word">
                          <span
                            class="text-color-gray"
                            style="font-weight: 500 !important;"
                            >{{ slotProps.data.email_id }}</span
                          >
                        </div>
                      </div>
                      <div class="col-12 md:col-2">
                        <!-- <i
                          @click="edit_customer(slotProps.data)"
                          v-show="showButton[index]"
                          class="pi pi-pencil"
                          style="
                            font-size: 1rem;
                          
                            color: #28a745 !important;
                            cursor: pointer;
                          "
                        ></i>&nbsp; -->
                       

                        <i
                          @click="delete_toggle(slotProps.data)"
                          v-show="showButton[index]"
                          class="pi pi-trash"
                          style="
                            font-size: 1rem;
                            color: red !important;
                            cursor: pointer;
                          "
                        ></i>  
                        <OverlayPanel ref="del" >
                            <div class="flex flex-column gap-3 w-25rem">
                              <h5>{{ $t('delete_cust') }} </h5>

                              <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                  <div class="field col-12 md:col-12">
                                    <span class="p-float-label">
                                      <Textarea
                                          format="text"
                                          v-model="delete_remark"
                                        >
                                      </Textarea>
                                      <label>{{ $t('remark') }}</label>
                                  </span>
                                  </div>

                                  <div class="field col-12 md:col-4" style="margin-left: 30%;">
                                    <Button  :label="$t('delete')" @click="delete_product"  class="p-button-primary btn_light_blue"/>
                                  </div>
                                  <div class="field col-12 md:col-4">
                                    <Button :label="$t('Cancel')" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                                  </div>

                                </div>
                              </div>

                            </div>
                        </OverlayPanel>
                        
                        <!-- --------------- -->

                      </div>
                      <div class="col-12 md:col-2">
                        <!-- <Badge v-tooltip.bottom="'Total Ticket'" style="background-color: #00b0b0!important" :value="slotProps.data.all_ticket?slotProps.data.all_ticket:'0'"></Badge> -->
                      </div>
                      <div class="col-12 md:col-2">
                        <!-- <Badge v-tooltip.bottom="'Open Ticket'" style="background-color: #00b064!important" :value="slotProps.data.open_ticket?slotProps.data.open_ticket:'0'"></Badge> -->
                      </div>
                      <div class="col-12 md:col-2">
                        <!-- <Badge v-tooltip.bottom="'In Progress Ticket'" style="background-color: #ffc107!important" :value="slotProps.data.inprogress_ticket?slotProps.data.inprogress_ticket:'0'"></Badge> -->
                      </div>
                      <div class="col-12 md:col-2">
                        <!-- <Badge v-tooltip.bottom="'Closed Ticket'" style="background-color: #e67d3c !important" :value="slotProps.data.close_ticket?slotProps.data.close_ticket:'0'"></Badge> -->
                      </div>
                      <div class="col-12 md:col-2">&nbsp;</div>
                      <div class="col-12 md:col-2">
                        <InputSwitch
                          v-model="slotProps.data.email_status"
                          v-on:change="verify_email(slotProps.data)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </DataView>
        </div>
      </div>
    </div>
  </template>
    <script>
  import apis from "@/apis";
  import axios from "axios";
  import ColorService from "../service/ColorService";
  import downloadexcel from "vue-json-excel3";
  export default {
    data() {
      return {
        //rowsPerPage:8,
        totalRecords: 0,
        //perPageOptions: [8, 16, 24, 100],
       // paginationarray: [9, 18, 27],
       PerPageOptions: [9, 18, 50, 100],
        temp_product_id:"",
        delete_remark:"",
        ColorService:null,
        color_data:[],
        url:"https://ui-avatars.com/api/?name=Test customer&amp;size=100&amp;rounded=true&amp;",
        search_data: "",
        isLoadingModel: false,
        fullPage: false,
        dataviewValue: [],
        layout: "grid",
        sortKey: null,
        sortOrder: null,
        sortField: null,
        excelData:null,
        user_type: "",
        client_id: "",
        showButton: [],
        id: "",

        client_dropdown:[],
        client_selected:"",
        // clientid:"",
        jsonFields: {   
          "Sr No": "sr_no",
         "Name ": "name",
         "Email": "email_id", 
         "Mobile" :"mobile_number",
         "Gender" :"gender",
         "Status" : "status",
         "About" : "about",
         "All Ticket": "all_ticket",
          "Open Ticket":"open_ticket",
          "In Progress Tickt" : "inprogress_ticket",
          "Close Ticket" : "close_ticket"
      },
      };
    },
    productService: null,
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
  },
    created() {
      if (this.$i18n.locale == 'en') {
        this.jsonFields={
          "Sr No": "sr_no",
          "Name ": "name",
         "Email": "email_id", 
         "Mobile" :"mobile_number",
         "Gender" :"gender",
         "Status" : "status",
         "About" : "about",
         "Total Ticket": "all_ticket",
          "Open Ticket":"open_ticket",
          "In Progress Tickt" : "inprogress_ticket",
          "Close Ticket" : "close_ticket"
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
          "नाव ": "name",
         "ई - मेल आयडी": "email_id", 
         "मोबाईल नंबर" :"mobile_number",
         "लिंग" :"gender",
         "स्थिती" : "status",
         "बद्दल" : "about",
         "एकूण तिकीट": "all_ticket",
          "नवीन तक्रार":"open_ticket",
          "प्रगतीपथावर असलेले तिकीट" : "inprogress_ticket",
          "एकूण बंद तक्रार" : "close_ticket"
        }
      }
      this.ColorService = new ColorService();
     // this.color_data = this.ColorService.getColors();
      
    },
    watch :{
      async search_data() {
    if (this.search_data) {
      this.search(1);
    }
  },
  

      currentLocale(){
        if (this.currentLocale == 'en') {
        this.jsonFields={
          "Sr No": "sr_no",
          "Name ": "name",
         "Email": "email_id", 
         "Mobile" :"mobile_number",
         "Gender" :"gender",
         "Status" : "status",
         "About" : "about",
         "Total Ticket": "all_ticket",
          "Open Ticket":"open_ticket",
          "In Progress Tickt" : "inprogress_ticket",
          "Close Ticket" : "close_ticket"
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
          "नाव ": "name",
         "ई - मेल आयडी": "email_id", 
         "मोबाईल नंबर" :"mobile_number",
         "लिंग" :"gender",
         "स्थिती" : "status",
         "बद्दल" : "about",
         "एकूण तिकीट": "all_ticket",
          "नवीन तक्रार":"open_ticket",
          "प्रगतीपथावर असलेले तिकीट" : "inprogress_ticket",
          "एकूण बंद तक्रार" : "close_ticket"
        }
      }
      },
    },
    async mounted() {
      this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });
     

      
      this.user_type = localStorage.getItem("user_type");
      this.client_id = localStorage.getItem("client_id");
      await this.get_list();
      await this.get_count();
      await this.getClientMaster();
    },
    methods: {
      async search(count) {
      if (count == 2) {
        this.search_data = "";
      }
      await this.get_list();
      await this.get_count();
    },

    async view_customer(item)
    { 
      await localStorage.setItem('CustomerDetails','');
      await localStorage.setItem('CustomerDetails',JSON.stringify(item));
      this.$router.push({ name: "customerdetails" });
    },
    convert_name(name)
      {
        if(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
      } 
      else
      {
        var x= "NA";
        return x.toString();
      }
      },
    
      async verify_email(data) {
        console.log(data);
        var status = "";
        if (data.email_status == true) {
          status = "Active";
        } else {
          status = "In Active";
        }
        let details = {
          user_id: data._id,
          status: status,
          type: "Status",
          lang:this.$i18n.locale
        };
        this.isLoadingModel = true;
        var promise = apis.updateUserStatus(details);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
          this.get_list();
          this.get_count();
        });
      },
      async confirmDeleteProduct(product) {
        this.$confirm.require({
          target: event.currentTarget,
          //message: "Are you sure you want to Delete customer?",
          message:  this.$t('confirm_delete_customer'),
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            var payload = {
              "user_id": product._id,
              "status":"Deleted",
              "type":"Status",
              lang:this.$i18n.locale
              
            };
            this.isLoadingModel = true;
            var promise = apis.updateUserStatus(payload);
            promise.then((response) => {
              this.isLoadingModel = false;
              if (response.data.status == true) {
                this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                this.get_list();
                this.get_count();
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "ERROR!!!",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Cancel",
              detail: "You have Cancelled",
              life: 3000,
            });
          },
        });
      },
      fetchData() {
      var temp_data = [];
      for (let i = 0; i < this.excelData.length; i++) {
        var item = this.excelData[i];
        var sr_no = i + 1 ;
        temp_data.push({
          sr_no: sr_no,
          name : item.name,
          email_id : item.email_id,
          mobile_number : item.mobile_number,
          gender : item.gender,
          status :item.status,
          about: item.about,
          all_ticket : item.all_ticket,
          open_ticket : item.open_ticket,
          inprogress_ticket : item.inprogress_ticket,
          close_ticket : item.close_ticket,
        });
      }
      return temp_data;
    },

      delete_toggle(data) 
      { 
        this.temp_product_id=data._id;
        this.$refs.del.toggle(event);
      },

      delete_product(){
       
        if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined) 
        {
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('pleaseEnterRemark'),
              life: 3000,
          });
          return false;
        }

        
        {
          var payload = {
            "user_id": this.temp_product_id,
            "status":"Deleted",
            "type":"Status",
            "remark":this.delete_remark,
            lang:this.$i18n.locale
          };
          this.isLoadingModel = true;
          var promise = apis.updateUserStatus(payload);

          promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status == true) {
             // this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
              this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: response.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
              this.get_list();
              this.get_count();
              this.temp_product_id="";
              this.delete_remark="";
              window.scrollTo({ top: 0, behavior: "smooth" });
            } 
            
            else {
              this.$toast.add({severity: "error", summary: "ERROR!!!", detail: response.data.message, life: 3000,
              });
            }
          });
        }
      },

      Cancel_delete(){
        this.temp_product_id="";
        this.delete_remark="";
        this.$refs.del.hide();
      },

      async add_customer() {
          this.$router.push({ name: "CustomerAddEdit" });
      },
      async edit_customer(data) {
          await localStorage.setItem("CustomerDetails", "");
          await localStorage.setItem("CustomerDetails",JSON.stringify(data));
          this.$router.push({ name: "CustomerAddEdit" });
      },    
      async getClientMaster() {
        var data = {
            limit: 10000,
            page_no: 1,
            count: false,
            user_id: localStorage.getItem("id"),
        };
      
        var promise = apis.getclientist(data);
        promise.then((response) => {
        this.client_dropdown = response.data.data;
        });
      },
      async get_count () {
        
        this.client_id = "";
        this.client_id = this.client_selected._id;
      
        if (this.client_id=='' || this.client_id==undefined) 
        {
          this.client_id=localStorage.getItem("client_id");
        }
        this.dataviewValue=[];
        var data = {
          // client_id: "65a4f82f6177e69880ece5d6",
          client_id: this.client_id,
          search: this.search_data,
          user_id: localStorage.getItem("id"),
          count:true,

        };
        this.isLoadingModel = true;
        var promise = apis.customerList(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.totalRecords = response.data.data;
          console.log("totalrecord",this.totalRecords);
          if(this.totalRecords>100)
          {
            this.PerPageOptions.push(this.totalRecords);     
          }
        });
      },

      async get_list() {
        this.client_id = "";
        this.client_id = this.client_selected._id;
      
        if (this.client_id=='' || this.client_id==undefined) 
        {
          this.client_id=localStorage.getItem("client_id");
        }
        this.dataviewValue=[];
        var data = {
          // client_id: "65a4f82f6177e69880ece5d6",
          client_id: this.client_id,
          search: this.search_data,
          user_id: localStorage.getItem("id"),
          count:false,

        };
        this.isLoadingModel = true;
        var promise = apis.customerList(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.dataviewValue = response.data.data;
          this.excelData = response.data.data;
          console.log("excel",this.excelData)
          for (let index = 0; index < this.dataviewValue.length; index++) 
          {
            if (this.dataviewValue[index].status == "Active") {
              this.dataviewValue[index].email_status = true;
            } else {
              this.dataviewValue[index].email_status = false;
            }

            
          }
        });
      },
      image(name)
      {
        var link='https://ui-avatars.com/api/?name='+name+'&size=100&rounded=true&color=fff&background='+this.generateRandomColor();
        return link;
      },
      generateRandomColor() {
        // Generate random values for red, green, and blue components
        var red = Math.floor(Math.random() * 256);
        var green = Math.floor(Math.random() * 256);
        var blue = Math.floor(Math.random() * 256);
      
        // Convert decimal values to hex and ensure two digits for each
        var redHex = red.toString(16).padStart(2, '0');
        var greenHex = green.toString(16).padStart(2, '0');
        var blueHex = blue.toString(16).padStart(2, '0');
      
        // Concatenate the hex values to form the color code
        var colorCode = redHex + greenHex + blueHex;
      
        return colorCode;
      },
      showButtons(index) {
        //this.$set(this.showButton, index, true);
        this.showButton[index] = true;
      },
      hideButtons(index) {
        //this.$set(this.showButton, index, false);
        this.showButton[index] = false;
      },
    },
    components: { downloadexcel, },
  };
  </script>
    <style scoped >
  @import "../assets/demo/badges.scss";
  #custom_card {
    border-top: 3px solid #6b719b;
  }
  #custom_card_left {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 0px !important;
  }
  .zoom {
    transform-origin: 0% 0% 1% 1%;
    transition: transform 1s, filter 1s ease-in-out;
  }
  /* The Transformation */
  .zoom:hover {
    transform: scale(1.1);
  }
  .border-info {
    border-left: 5px solid #0dcaf0 !important;
  }
  .text-color-gray {
    color: gray !important;
  }
  </style>
    